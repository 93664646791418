<template>
    <v-data-table
        id="campaigns-table"
        ref="datatable"
        :items="consolidatedResult"
        :headers="platformObjectHeaders"
        item-key="platform"
        :expand="true"
        class="pb-4"
        hide-actions
        :hide-headers="index > 0">
        <template
            slot="headerCell"
            slot-scope="props">
            <div
                v-if="props.header.text === 'impr'"
                class="divided-wrapper">
                <span class="divided">IMPR</span>
                <div>CPM</div>
            </div>
            <div
                v-else-if="props.header.text === 'clicks'"
                class="divided-wrapper">
                <span class="divided">CLICKS</span>
                <div>CPC</div>
            </div>
            <div
                v-else-if="props.header.text === 'leads'"
                class="divided-wrapper">
                <span class="divided">LEADS</span>
                <div>CPL</div>
            </div>
            <div
                v-else-if="props.header.text === 'sales'"
                class="divided-wrapper">
                <span class="divided">SALES</span>
                <div>CPS</div>
            </div>
            <span v-else>{{ props.header.text }}</span>
        </template>
        <template #items="props">
            <tr class="blue-bg">
                <td class="centered w4">
                    <styled-checkbox
                        v-if="!loading"
                        class="h15"
                        :row="false"
                        :disabled="Boolean(error)"
                        :checked="Boolean(selectedAdSets.length)"
                        @change="toggleSelectAll" />
                    <loader
                        v-else
                        size="18" />
                </td>
                <td class="w4">
                    <!-- @todo get this working again -->
                    <!-- <button
                        v-if="!loading"
                        class="pt-1"
                        @click="props.expanded = !props.expanded">
                        <icon
                            size="20"
                            :class="{'is-open': props.expanded}"
                            name="chevron-down" />
                    </button> -->
                </td>
                <td class="w20">
                    <div class="channel-wrapper">
                        <icon
                            v-if="statsAreProcessing.status === 'running'"
                            class="channel-warning"
                            size="15"
                            :color="colors.yellow"
                            name="indicator-warning" />
                        <icon
                            v-if="statsAreProcessing.status === 'failed'"
                            class="channel-warning"
                            size="15"
                            :color="colors.red"
                            name="indicator-error" />
                        <icon
                            size="20"
                            :name="fbToMeta(props.item.platform, true)" />
                        <span class="channel-name">{{ fbToMeta(capitalizeFirstLetter(props.item.platform)) }}</span>
                        <div
                            v-if="statsAreProcessing.value"
                            class="channel-processing"
                            :class="{'failed': statsAreProcessing.status === 'failed'}">
                            <styled-tooltip
                                nudge-top="10"
                                position="top">
                                <template #content>
                                    <div v-if="statsAreProcessing.status === 'running'">
                                        {{ capitalizeFirstLetter(props.item.platform) }} stats are currently being populated for this date range.
                                        Please check back soon to see your data!
                                    </div>
                                    <div v-else-if="statsAreProcessing.status === 'failed' && props.item.platform === PLATFORM_PINTEREST">
                                        {{ capitalizeFirstLetter(props.item.platform) }}  stats processing failed. Sometimes this can be resolved by clicking retry.
                                        If not, click the info icon for additional steps to resolve the issue.
                                    </div>
                                    <div v-else>
                                        There was an issue getting your data from {{ capitalizeFirstLetter(props.item.platform) }}.
                                        Click the info icon for additional steps to resolve this issue and if you are unable to resolve please contact support
                                    </div>
                                </template>
                                <div class="info-failed">
                                    <v-progress-circular
                                        v-if="statsAreProcessing.status === 'running'"
                                        class="mr-2"
                                        size="15"
                                        width="2"
                                        color="primary"
                                        indeterminate />
                                    <span v-if="statsAreProcessing.status === 'running'">Stats processing...</span>
                                    <span v-else-if="statsAreProcessing.status === 'failed' && props.item.platform === PLATFORM_PINTEREST">Stats failed</span>
                                    <span v-else>Connection failed</span>
                                    <div
                                        v-if="statsAreProcessing.status === 'failed'"
                                        class="info-failed">
                                        <a
                                            v-if="props.item.platform === PLATFORM_PINTEREST"
                                            class="ml-2"
                                            @click="makeSyncAndRetry(props.item.platform)">retry</a>
                                        <span @click="checkConnection(props.item.platform)">
                                            <icon
                                                class="ml-2 info-icon"
                                                size="15"
                                                name="indicator-info" />
                                        </span>
                                    </div>
                                </div>
                            </styled-tooltip>
                        </div>
                    </div>
                </td>
                <td class="centered w20">
                    <status-toggle
                        v-if="!loading && !objectCountLoading"
                        :value="filterStatus"
                        :count="isFiltered ? undefined : props.item.status.noOfActives"
                        @input="changeFilterStatus" />
                    <loader
                        v-else
                        size="18" />
                </td>
                <td class="w10">
                    &nbsp;
                </td>
                <td class="centered w8">
                    <empty-field v-if="statsAreProcessing.value" />
                    <template v-else-if="!isFiltered">
                        {{ formatNumber(props.item.spend, 'currency') }}
                    </template>
                </td>
                <td class="centered darken w8 totals-tooltip-cell">
                    <totals-tooltip
                        v-if="!isFiltered"
                        type="sets" />
                    <empty-field v-if="statsAreProcessing.value" />
                    <div
                        v-else-if="!isFiltered"
                        class="divided-wrapper">
                        <span class="divided">{{ formatNumber(props.item.impressions, 'number') }}</span>
                        <div>{{ formatNumber(props.item.cpm, 'currency') }}</div>
                    </div>
                </td>
                <td class="centered darken w8">
                    <empty-field v-if="statsAreProcessing.value" />
                    <div
                        v-else-if="!isFiltered"
                        class="divided-wrapper">
                        <span class="divided">{{ formatNumber(props.item.clicks, 'number') }}</span>
                        <div>{{ formatNumber(props.item.cost_per_click, 'currency') }}</div>
                    </div>
                </td>
                <td class="centered darken w8">
                    <empty-field v-if="statsAreProcessing.value" />
                    <div
                        v-else-if="!isFiltered"
                        class="divided-wrapper">
                        <span class="divided">{{ formatNumber(props.item.leads, 'number') }}</span>
                        <div>{{ formatNumber(props.item.cost_per_lead, 'currency') }}</div>
                    </div>
                </td>
                <td class="centered darken w8">
                    <empty-field v-if="platform === PLATFORM_TIKTOK || statsAreProcessing.value" />
                    <div
                        v-else-if="!isFiltered"
                        class="divided-wrapper">
                        <span class="divided">{{ formatNumber(props.item.sales, 'number') }}</span>
                        <div>{{ formatNumber(props.item.cost_per_sale, 'currency') }}</div>
                    </div>
                </td>
                <td class="darken w2">
                    <vertical-menu>
                        <a
                            class="menu-link"
                            :href="adsManagerLink(props.item.platform)"
                            target="_blank">
                            View {{ capitalizeFirstLetter(props.item.platform) }} Ads Manager
                        </a>
                        <a
                            v-if="platform === PLATFORM_PINTEREST"
                            class="menu-link with-line"
                            :href="dealerPinterestDynamicProductUrl"
                            target="_blank">
                            View {{ capitalizeFirstLetter(props.item.platform) }} Dynamic Product Ads
                        </a>
                    </vertical-menu>
                </td>
            </tr>
        </template>
        <template #expand="{ item }">
            <table
                v-if="!error"
                class="expand-table">
                <!-- active platform object -->
                <tbody
                    v-for="page in pages"
                    :key="page.key">
                    <table-row
                        v-for="adSet in page.objects"
                        :key="adSet.external_id"
                        :platform-object="adSet"
                        :channel-name="item.platform"
                        :running="page.running"
                        type="adsets"
                        :selected="selectedAdSets.includes(adSet.external_id)"
                        @select="toggleAdSetSelection(adSet, $event)"
                        @update-ad-set="updateAdSet"
                        @open="openAdSetAds" />
                </tbody>
            </table>
            <div
                v-if="loading || loadingNextPage"
                class="text-xs-center">
                <styled-loader
                    :size="18"
                    class="load-more" />
            </div>
            <div
                v-if="Boolean(nextPage) && !error"
                :key="pages.length"
                v-intersect="onNextPage"
                class="next-page" />
            <div
                v-if="error"
                class="error-section">
                <p>{{ error }}</p>
            </div>
            <div
                v-if="!loading && !allAdSets.length"
                class="no-data">
                <p>There are no ad sets for the selected criteria.</p>
            </div>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels';
import VerticalMenu from '@/components/globals/VerticalMenu';
import EmptyField from '@/components/globals/EmptyField';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import StyledLoader from '@/components/globals/StyledLoader';
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/StyledLoader.vue';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
import TableRow from '../TableRow';
import TotalsTooltip from '../TotalsTooltip';
import StatusToggle from '../StatusToggle';
import moment from 'moment';
import {
    OBJECT_TYPE_ADSET,
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK
} from '@/helpers/globals';
import getObjectFromPages from '../helpers/getObjectFromPages';
import mergeEditedObject from '../helpers/mergeEditedObject';
import colors from '@/helpers/colors';
import { SET_CHANNEL_CONNECTION_DEBUG_PANEL } from '@/store/mutation-types';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    components: {
        StyledTooltip,
        VerticalMenu,
        EmptyField,
        StyledCheckbox,
        StyledLoader,
        Icon,
        Loader,
        TableRow,
        StatusToggle,
        TotalsTooltip
    },
    props: {
        platform: {
            type: String,
            required: true
        },
        search: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            colors,
            statsAreProcessing: {
                value: false,
                status: ''
            },
            error: '',
            nextPage: null,
            pages: [],
            loading: false,
            loadingNextPage: false,
            showStatusFilter: false,
            filterStatus: 'ACTIVE',
            objectCountLoading: false,
            facebookObjectCount: 0,
            firstInit: true,
            PLATFORM_TIKTOK,
            PLATFORM_PINTEREST,
            fbToMeta
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            dateRange: (state) => state.metrics.dateRange,
        }),
        ...mapGetters('playbookManagement', [
            'getNormalizedMetricTotals'
        ]),
        ...mapGetters([
            'dealerFacebookAdAccountUrl',
            'dealerTiktokAdAccountUrl',
            'dealerSnapchatAdAccountUrl',
            'dealerPinterestAdAccountUrl',
            'dealerPinterestDynamicProductUrl'
        ]),
        selectedCampaigns() {
            return this.$store.state.playbookManagement.selectedCampaigns[this.platform];
        },
        selectedAdSets() {
            return this.$store.state.playbookManagement.selectedAdSets[this.platform];
        },
        isFiltered() {
            return Boolean(
                this.selectedCampaigns.length
            );
        },
        pageSize() {
            if ([PLATFORM_FACEBOOK, PLATFORM_PINTEREST].includes(this.platform)) {
              return 10;
            }

            // @todo Remove high pagination for other channels once
            // the server properly handles filtering, etc
            return 1000;
        },
        channelDetails() {
            return getChannelByPlatformName(this.platform);
        },
        platformObjectHeaders() {
            return [
                {
                    text: '',
                    align: '',
                    sortable: false,
                    width: '4%',
                },
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    width: '4%',
                    value: 'data-table-expand'
                },
                {
                    text: 'CHANNEL',
                    align: 'left',
                    sortable: false,
                    value: 'channel',
                    width: '20%',
                },
                {
                    text: 'STATUS',
                    align: 'center',
                    sortable: false,
                    width: '20%',
                },
                {
                    text: 'BUDGET',
                    align: 'center',
                    sortable: false,
                    width: '10%',
                },
                {
                    text: 'SPEND',
                    align: 'center',
                    sortable: false,
                    width: '8%',
                },
                {
                    text: 'impr',
                    align: 'center',
                    sortable: false,
                    width: '8%',
                },
                {
                    text: 'clicks',
                    align: 'center',
                    sortable: false,
                    width: '8%',
                },
                {
                    text: 'leads',
                    align: 'center',
                    sortable: false,
                    value: 'leads',
                    width: '8%',
                },
                {
                    text: 'sales',
                    align: 'center',
                    sortable: false,
                    value: 'sales',
                    width: '8%',
                },
                {
                    text: '',
                    align: '',
                    sortable: false,
                    width: '2%',
                },
            ];
        },
        consolidatedResult() {
            return [{
                platform: this.platform,
                status: {
                    type: this.getOverallChannelStatus,
                    noOfActives: this.adSetCount
                },
                ...this.getNormalizedMetricTotals(this.platform) ?? {}
            }];
        },
        getOverallChannelStatus() {
            return this.filterStatus === 'ACTIVE' ? 'active' : ''; //PR
        },
        allAdSets() {
            return this.pages.reduce((all, current) => {
                all = all.concat(current.objects);
                return all;
            }, []);
        },
        adSetCount() {
            if (this.platform === PLATFORM_FACEBOOK) {
                return this.facebookObjectCount;
            }

            return this.allAdSets.length;
        }
    },
    watch: {
        allAdSets(objects) {
            this.setPlatformObjects({
                type: OBJECT_TYPE_ADSET,
                platform: this.platform,
                objects
            });
        },
        search() {
            this.init();
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions('playbookManagement', {
            selectObjects: 'select',
            unselectObjects: 'unselect',
            openObjects: 'open',
            removeChildren: 'removeChildren',
            setPlatformObjects: 'setPlatformObjects'
        }),
        checkConnection(channel) {
            this.$store.commit(SET_CHANNEL_CONNECTION_DEBUG_PANEL, { status: true, channel, dealer_id: this.currentDealerId });
        },
        async makeSyncAndRetry(channel) {
            await this.makeFullSync(channel);
            await this.init();
        },
        async makeFullSync(channel) {
            this.loading = true;
            try {
                await this.$apiRepository.queueDealerAdStatsSync({ dealerId: this.currentDealerId, platform: channel });
            } catch(error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async init() {
            this.statsAreProcessing = {
                value: false,
                status: ''
            };
            this.pages = [];
            this.nextPage = null;
            this.$refs.datatable.expanded[this.platform] = true;
            this.loading = true;
            this.firstInit = false;

            await this.getAdsetsPage();

            this.loading = false;

            if (this.platform === PLATFORM_FACEBOOK) {
                await this.getFacebookObjectCount();
            }
        },
        async onNextPage() {
            if (!this.loadingNextPage && this.nextPage) {
                this.loadingNextPage = true;
                await this.getAdsetsPage();
                this.loadingNextPage = false;
            }
        },
        async changeFilterStatus(value) {
            this.filterStatus = value;
            this.showStatusFilter = false;
            this.init();
        },
        async getAdsetsPage() {
            try {

                const params = {
                    stats: false,
                    page_size: this.pageSize,
                };

                if (this.selectedCampaigns.length) {
                    params.campaignIds = this.selectedCampaigns;
                }

                if (this.search) {
                    params.search = this.search;
                }

                if (this.filterStatus) {
                    params.status = this.filterStatus;
                }

                if (this.nextPage) {
                    params.page = this.nextPage;
                }

                const response = await this.$apiRepository.getDealerPlatformAdSets({
                  dealerId: this.currentDealerId,
                  platform: this.platform,
                  params
                });

                if (response.data.data.length) {
                    const pageKey = this.nextPage;

                    this.pages.push({
                        key: pageKey,
                        loading: true,
                        running: false,
                        objects: response.data.data
                    });

                    this.getAdSetsPageWithStats(pageKey, this.nextPage);
                }

                // If there's another page to load track it
                this.nextPage = response.data?.meta?.next_page || null;
            } catch (error) {
                this.statsAreProcessing = {
                    value: true,
                    status: 'failed'
                };
                this.error = 'There was an error retrieving data from this platform.  Please try resetting the report or contact support.';
                console.log(this.error, error.response || error);
            }
        },
        async getAdSetsPageWithStats(pageKey, pageCursor) {
            try {

                const params = {
                    start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                    stats: true,
                    page_size: this.pageSize
                };

                if (this.selectedCampaigns.length) {
                    params.campaignIds = this.selectedCampaigns;
                }

                if (this.search) {
                    params.search = this.search;
                }

                if (this.filterStatus) {
                    params.status = this.filterStatus;
                }

                if (pageCursor) {
                    params.page = pageCursor;
                }

                const pageIndex = this.getPageIndex(pageKey);

                const response = await this.$apiRepository.getDealerPlatformAdSets({
                  dealerId: this.currentDealerId,
                  platform: this.platform,
                  params
                });

                this.$set(this.pages, pageIndex, {
                    key: pageKey,
                    loading: false,
                    running: false,
                    objects: response.data.data
                });

            } catch (error) {
                const pageIndex = this.getPageIndex(pageKey);
                const newPage = { ...this.pages[pageIndex] };
                newPage.loading = false;
                this.$set(this.pages, pageIndex, newPage);
                console.log(this.error, error.response || error);
                if (error.response.data.full_sync == 'running' ||
                    error.response.data.incremental_sync == 'running' ||
                    error.response.data.full_sync == 'queued' ||
                    error.response.data.incremental_sync == 'queued') {
                    newPage.running = true;
                    this.statsAreProcessing = {
                        value: true,
                        status: 'running'
                    };
                } else if (error.response.data.full_sync == 'failed' || error.response.data.incremental_sync == 'failed') {
                    newPage.running = true;
                    this.statsAreProcessing = {
                        value: true,
                        status: 'failed'
                    };
                } else {
                    this.error = 'There was an error retrieving data from this platform.  Please try resetting the report or contact support.';
                }
            }
        },
        async getFacebookObjectCount() {
            try {
                this.objectCountLoading = true;

                const params = {};

                if (this.selectedCampaigns.length) {
                    params.campaignIds = this.selectedCampaigns;
                }

                if (this.filterStatus) {
                    params.status = this.filterStatus;
                }

                const response = await this.$http.get(`/dealers/${this.currentDealerId}/platforms/${this.platform}/ad-sets/count`, params);
                this.facebookObjectCount = response.data.count;
            } catch (error) {
                this.error = 'There was an error retrieving data from this platform.  Please try resetting the report or contact support.';
                console.log(this.error, error.response || error);
            } finally {
                this.objectCountLoading = false;
            }
        },
        getPageIndex(pageKey) {
            return this.pages.findIndex((page) => (
                page.key === pageKey
            ));
        },
        toggleAdSetSelection(adSet, checked) {
            if (checked) {
                this.selectObjects({
                    platform: this.platform,
                    type: OBJECT_TYPE_ADSET,
                    objects: [adSet.external_id]
                });

                // Remove any selections downstream
                this.removeChildren(OBJECT_TYPE_ADSET);

            } else {
                this.unselectObjects({
                    platform: this.platform,
                    type: OBJECT_TYPE_ADSET,
                    objects: [adSet.external_id]
                });
            }
        },
        toggleSelectAll(event) {
            const { checked } = event.target;

            if (checked) {
                this.selectObjects({
                    platform: this.platform,
                    type: OBJECT_TYPE_ADSET,
                    objects: this.allAdSets.map(adSet => adSet.external_id),
                });
            } else {
                this.unselectObjects({
                    platform: this.platform,
                    type: OBJECT_TYPE_ADSET,
                    objects: this.allAdSets.map(adSet => adSet.external_id),
                });
            }
        },
        openAdSetAds(adSet) {
            this.openObjects({
                platform: this.platform,
                type: OBJECT_TYPE_ADSET,
                objects: [ adSet.external_id ],
            });
        },
        getFormattedMetrics(stats) {
            if (!stats) {
                return;
            }

            const formattedMetrics = {};

            Object.keys(stats).forEach((item) => {
                formattedMetrics[item] = stats[item].value;
            });

            return formattedMetrics;
        },
        updateAdSet(adSet) {
            const {
                pageIndex,
                objectIndex,
                object: oldAdSet
            } = getObjectFromPages(this.pages, adSet.external_id);

            if (oldAdSet) {
                const newAdSet = mergeEditedObject(oldAdSet, adSet);
                const page = { ...this.pages[pageIndex] };
                page.objects.splice(objectIndex, 1, newAdSet);
                this.$set(this.pages, pageIndex, page);
            }
        },
        formatNumber(number, type) {
            const formats = getNumberFormats();

            if (!number && type === 'number') {
                return 0;
            }

            return numeral(number).format(formats[type]);
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        reset() {
            this.pages = [];
            this.filterStatus = 'ACTIVE';
            this.nextPage = null;
        },
        adsManagerLink(channel) {
            switch (channel) {
                case PLATFORM_FACEBOOK:
                    return this.dealerFacebookAdAccountUrl;
                case PLATFORM_TIKTOK:
                    return this.dealerTiktokAdAccountUrl;
                case PLATFORM_SNAPCHAT:
                    return this.dealerSnapchatAdAccountUrl;
                case PLATFORM_PINTEREST:
                    return this.dealerPinterestAdAccountUrl;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.load-more {
    margin-top: 10px;
}
.no-data {
    padding: 30px 50px 10px;
}
.error-section {
    padding: 30px 50px 10px;
    color: $error-500;
}
.menu-link {
    display: block;
    white-space: nowrap;
    margin-right: 5px;
}
.with-line {
    margin-top: 30px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: -15px;
        left: -5px;
        right: -5px;
        width: calc(100% + 10px);
        height: 1px;
        background: $border-list;
    }
}
.channel-wrapper {
    position: relative;
}
.channel-warning {
    position: absolute;
    z-index: 9;
    background: white;
    border-radius: 50%;
    top: -5px;
    left: -7px;
}
.channel-processing {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -13px;
    white-space: nowrap;
    font-size: 10px;
    color: $gray;
    background: $lightblue-bg;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 9;
}
.channel-processing.failed {
    background: $light-error;
}
.info-icon {
    cursor: pointer;
}
.info-failed {
    display: flex;
    align-items: center;
}
</style>
