<template>
    <div class="tab-content">
        <div class="tab-header flexed">
            <div class="search-wrapper">
                <v-text-field
                    class="styled-field"
                    label="Search"
                    clearable
                    placeholder="Enter terms..."
                    @input="handleSearch" />
            </div>
        </div>
        <div class="table-wrapper">
            <platform-ad-sets
                v-for="(platform, index) in filteredPlatforms"
                :key="platform"
                :search="search"
                :platform="platform"
                :index="index" />
        </div>
    </div>
</template>

<script>
import debounce from 'debounce';
import { mapState } from 'vuex';
import PlatformAdSets from './PlatformAdSets';

export default {
    name: 'AdSets',
    components: {
        PlatformAdSets
    },
    props: {
        platforms: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            search: ''
        };
    },
    computed: {
        ...mapState({
            selectedCampaigns: (state) => state.playbookManagement.selectedCampaigns
        }),
        filteredPlatforms() {
            const selectedPlatforms = this.platforms.filter(platform => (
                this.selectedCampaigns[platform].length
            ));

            return selectedPlatforms.length ? selectedPlatforms : this.platforms;
        }
    },
    methods: {
        handleSearch: debounce(function(input) {
            this.search = input;
        }, 400)
    }
};
</script>